<template>
	<div class="createParks">
		<div>
			<div class="main-content-title">
				<p>共有 {{ total }} 条结果</p>
				<el-button type="primary" @click="modifyParkInfo($event, false)" icon="el-icon-plus">新增</el-button>
			</div>
			<Table
				:loading="loading"
				:table-data="tableData"
				:total="total"
				:table-head="tableHead"
				:current-page.sync="queryData.current"
				:page-size.sync="queryData.size"
				:is-show-selection="false"
				:operation-column-width="140"
				:showOperation="true"
				@change-page="getList"
				:tableHeight="tableHeight"
			>
				<template #enabled="{ scope }">
					<el-switch inactive-text="否" active-text="是" v-model="scope.row.enabled" @change="changeOpen(scope.row)"> </el-switch>
				</template>
				<template #operation="{ scope }">
					<el-button @click="modifyParkInfo($event, true, scope.row)" size="mini" type="primary"> 查看 </el-button>
					<el-button
						@click="modifyParkInfo($event, false, scope.row)"
						size="mini"
						:type="scope.row.enabled ? 'info' : 'warning'"
						:disabled="scope.row.enabled"
					>
						修改
					</el-button>
				</template>
			</Table>
		</div>
		<createView ref="createView" @getList="getList" :isShowDetail="isShowDetail" />
		<ShowList ref="showList" />
	</div>
</template>

<script>
export default {
	name: 'addTraning',
	components: {
		Table: () => import('@/components/Table/table'),
		createView: () => import('./newDialog.vue'),
		ShowList: () => import('./showList.vue'),
	},
	data() {
		return {
			loading: false,
			queryData: {
				current: 1,
				size: 10,
			},
			total: 0,
			tableData: [],
			tableHead: [
				{
					label: '培训名称',
					prop: 'name',
					formatter: (row) => {
						return row.name || '-';
					},
				},
				{
					label: '报名时间',
					prop: 'registerStartTime',
					width: 170,
					formatter: (row) => {
						return row.registerEndTime ? row.registerStartTime + '-' + row.registerEndTime : '-';
					},
				},
				{
					label: '培训开始时间',
					prop: 'startTime',
					width: 160,
					formatter: (row) => {
						return row.startTime || '-';
					},
				},
				{
					label: '培训地点',
					prop: 'address',
					formatter: (row) => {
						return row.address || '-';
					},
				},
				{
					label: '培训费用',
					prop: 'price',
					formatter: (row) => {
						return row.price ? (row.price / 100).toFixed(2) : '-';
					},
				},
				{
					label: '是否启用',
					prop: 'enabled',
					width: 110,
				},
			],
			isShowDetail: false,
			link: '',
		};
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 550;
		},
	},
	mounted() {
		this.getList();
	},
	methods: {
		//获取列表
		getList() {
			if (!this.$route.query.id) return;
			this.loading = true;
			let data = {
				...this.queryData,
				famousTeacherLectureId: this.$route.query.id,
			};
			this.$http
				.get(this.api.getLectureTrains, { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						this.link = res.data._links.create.href || '';
						this.tableData = res.data.collection || [];
						this.total = res.data.pagination.totalItems || 0;
						this.loading = false;
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
		modifyParkInfo(event, isShowDetail, row) {
			let data = {
				row: row || null,
				link: row ? row._links.update.href : this.link,
			};
			this.$refs.createView.init(data);
			this.isShowDetail = isShowDetail;
		},
		changeOpen(row) {
			this.$http
				.put(row._links.update.href, row)
				.then((res) => {
					if (res.data && res.data.success) {
						this.$message.success('操作成功');
						this.getList();
					}
				})
				.catch((e) => {});
		},
	},
};
</script>

<style lang="scss" scoped>
.main-content {
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;
		.el-button {
			line-height: 32px;
			padding: 0 16px;
			height: 32px;
			margin-top: 8px;
		}
	}
}
</style>
